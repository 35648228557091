// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
//@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap";
//@import "dataTables";

.fa-middle {
  margin-top: 12.5px;
}

body {
  font-size: 14px !important;
}

.skin-green .sidebar-menu > li.header {
  height: 42px !important;
  line-height: 1.42857143 !important;
}

body{

  &.login-page{
    background: url(../img/login-background-1.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    body{
      background-color: transparent;
    }
  }
}

//Custom css

div.Container {
  background: rgba(255,255,255,0.5);
  padding: 10px;
  margin: 0 auto;
}

div.ContentWrapper {
  box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.4);
  padding: 20px;
  text-align: center;
}

div.align-right {
  text-align: right;
}

div.align-left {
  text-align: left;
}

div.Container div.ContentWrapper iframe.mobile {
  width: 375px;
  height: 667px;
}

div.Container div.ContentWrapper iframe {
  width: 100%;
  height: 80vh;
}

.nav-tabs > li {
  position:relative;
}
.nav-tabs > li > a {
  display:inline-block;
}
.nav-tabs > li > span {
  cursor:pointer;
  color: white;
}
.nav-tabs > li:hover > span {
  display: inline-block;
}

.nav-tabs-custom > .nav-tabs > li.active > a {
  border: none;
}

div.top-space {
  margin-top: 5vh;
}

div.space {
  padding: 10px;
}

.left {
  float: left;
}

.right {
  float: right;
}

.text-white {
  color: white !important;
}

.blue {
  color: #00c0ef;
}

.red {
  color: #dd4b39;
}

.distance {
  padding: 10px;
  padding-left: 0px;
}

.top-and-button {
  margin-top: 20px;
  margin-bottom: 95px;
}

.textarea-min-max {
  min-width: 100%;
  max-width: 100%;
  min-height: 200px;
  max-height: 200px;
}

.dracula-theme-textarea {
  background-color: #424447;
  color: #eff959;
}

.save {
  background-color: green;
  border-color: green;
}

.save:hover {
  opacity: 0.8;
  background-color: green;
  border-color: green;
}

.delete-button {
  background-color: red;
  border-color: red;
}

.delete-button:hover {
  opacity: 0.8;
  background-color: red;
  border-color: red;
}

.edit-button {
  background-color: orange;
  border-color: orange;
}

.edit-button:hover {
  opacity: 0.8;
  background-color: orange;
  border-color: orange;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.nobackground {
  background: none !important;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}
.badge-success {
  color: #fff;
  background-color: #28a745;
}
.badge-pill {
  padding-right: .6em;
  padding-left: .6em;
  border-radius: 10rem;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
  text-align: center;
}

.box .box-body iframe {
  width: 100%;
  height: 90vh;
}

tr.drag-item:hover {
  border: 1px solid black;
}

table.drag-item:hover {
  border: 1px solid black;
}


.edit-content {
  //display: none;
  font-size: 32px;
  position: absolute;
  left: 20px;
  cursor: pointer;
}

.remove-content {
  //display: none;
  font-size: 32px;
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.error {
  color: white;
  background-color: red;
  text-align: center;
  width: 100%;
  padding: 10px;
}

.empty-url {
  border: 1px solid red;
}

.center {
  text-align: center;
}

.error-spacing {
  width: 75%;
  margin: auto;
  margin-top: 15px;
}

table.drag-item:hover .edit-content {
  display: block;
}

table.drag-item:hover .remove-content {
  display: block;
}

@media (max-width: 990px) {
  .mobile-button {
    margin-top: -55px;
  }
}